html {
    font-family: "Space Grotesk", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
        Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
        Noto Color Emoji;
}

body {
    background: #fff;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

@media only screen and (max-width: 1024px) {
    body {
        margin-left: 3rem;
        margin-right: 3rem;
    }
}

@media only screen and (max-width: 768px) {
    body {
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 1rem;
        margin-bottom: 3rem;
    }
}

header {
    margin-bottom: 2rem;

    #avatar-container {
        width: 8rem;
        height: 8rem;
        border-radius: 10rem;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f6f6f6;
        margin-left: 2rem;
        img {
            width: 80%;
        }
    }

    #avatar-underline {
        height: 2.5rem;
        margin-top: -1.5rem;
        background: repeating-linear-gradient(45deg, #e5e8ff, #e5e8ff 10px, #f8f9fe 10px, #f8f9fe 20px);
        border-radius: 6rem;
    }
}

#content {
    margin: 0 0.5rem;
    color: #111;
    p:first-child {
        font-size: 2rem;
        margin-block-start: 1rem;
        margin-block-end: 1rem;
    }
    font-size: 1.25rem;
    a {
        color: inherit;
        text-decoration-color: #9aacff;
        -webkit-text-decoration-color: #9aacff;
    }
    h1 {
        font-size: 2rem;
    }
    h1:before {
        content: "";
        display: block;
        position: absolute;
        width: 10rem;
        height: 1.3rem;
        background: repeating-linear-gradient(45deg, #e5e8ff, #e5e8ff 10px, #f8f9fe 10px, #f8f9fe 20px);
        z-index: -1;
        margin-top: 1.3rem;
    }

    h2 {
        font-size: 1.3rem;
        font-weight: 400;
    }
    p.project-description {
        font-weight: 300;
    }
    .icon-small {
        width: 1rem;
        height: 1rem;
    }
}
