html {
  font-family: Space Grotesk, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

body {
  max-width: 1024px;
  background: #fff;
  margin: 3rem auto;
}

@media only screen and (max-width: 1024px) {
  body {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  body {
    margin: 1rem 1rem 3rem;
  }
}

header {
  margin-bottom: 2rem;
}

header #avatar-container {
  width: 8rem;
  height: 8rem;
  background-color: #f6f6f6;
  border-radius: 10rem;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

header #avatar-container img {
  width: 80%;
}

header #avatar-underline {
  height: 2.5rem;
  background: repeating-linear-gradient(45deg, #e5e8ff, #e5e8ff 10px, #f8f9fe 10px 20px);
  border-radius: 6rem;
  margin-top: -1.5rem;
}

#content {
  color: #111;
  margin: 0 .5rem;
  font-size: 1.25rem;
}

#content p:first-child {
  margin-block: 1rem;
  font-size: 2rem;
}

#content a {
  color: inherit;
  text-decoration-color: #9aacff;
}

#content h1 {
  font-size: 2rem;
}

#content h1:before {
  content: "";
  width: 10rem;
  height: 1.3rem;
  z-index: -1;
  background: repeating-linear-gradient(45deg, #e5e8ff, #e5e8ff 10px, #f8f9fe 10px 20px);
  margin-top: 1.3rem;
  display: block;
  position: absolute;
}

#content h2 {
  font-size: 1.3rem;
  font-weight: 400;
}

#content p.project-description {
  font-weight: 300;
}

#content .icon-small {
  width: 1rem;
  height: 1rem;
}

/*# sourceMappingURL=index.f0dfdc9a.css.map */
